<template>
  <footer id="footer" class="home_footer">
    <div class="footer_box1">
      <div class="footer_box1_left">
        <ul class="notice_top_list">
          <li class="item first">
            <span class="notice_text" @click="goNoticeList"> 공지사항 </span>
            <span class="notice_arrorw" @click="goNoticeList">
              <img class="arrow_img" src="/media/images/home/bounding_box.png" alt="더보기" />
            </span>
          </li>
          <li v-for="(noticeitem, noticeindex) in getFormatNoticeList" class="item item_wrap" :class="noticeindex === 2 ? 'last' : ''" :key="noticeitem.commonNoticeId">
            <span @click="goNoticeDetail(noticeitem.commonNoticeId)"> [공지] &nbsp;{{ noticeitem.commonNoticeTitle }} </span>
            <span class="notice_date">
              {{ noticeitem.formatDate }}
            </span>
          </li>
        </ul>
      </div>
      <div class="footer_box1_right">
        <ul class="faq_list">
          <li class="faq_item" @click="openOneToOne">1:1문의</li>
          <li class="faq_item" @click="openInquiry">제휴문의</li>
        </ul>
        <ul class="app_down_list">
          <li class="app_down_item">앱 다운로드</li>
          <li>
            <ul class="app_store_download_list">
              <li class="app_store_download_item apple" @click="clickAppStore('AppleStore')">
                <span class="app_down_imgcontainer">
                  <img class="store_img" src="/media/images/home/applestore.jpg" alt="애플스토어" />
                </span>
                App Store
              </li>
              <li class="app_store_download_item google_store" @click="clickAppStore('GoogleStore')">
                <span class="app_down_imgcontainer">
                  <img class="store_img" src="/media/images/home/googlestore.png" alt="구글스토어" />
                </span>
                Google Play
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="hr_line"></div>
    <div class="footer_box2">
      <div class="footer_box2_left">
        <h1 class="agree_information">
          <span class="agree_service" @click="goHomeService">서비스 이용약관</span>
          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          <span class="private_info" @click="goHomePrivacy">개인정보처리방침</span>
        </h1>
        <address class="aplayz_address">
          <div class="aplayz_info">
            <span class="info_item">상호명 : (주)어플레이즈</span>
            <span class="info_item">대표 : 배정진</span>
            <span class="info_item">사업장 소재지 : 서울 강남구 도산대로15길 12 가로수빌딩 3층</span>
            <span class="info_item">사업자 등록번호 : 756-86-02541</span>
          </div>
          <div class="aplayz_contact">
            <span class="span_contanct">문의 : contact@aplayz.co.kr </span>
            <span>고객센터 : 070-4060-8362</span>
          </div>
        </address>
      </div>
      <div class="footer_box2_right">
        <div class="sns_group">
          <div class="sns_item" @click="openInstgram">
            <img src="/media/images/home/instgram.png" alt="인스타그램" />
          </div>
          <div class="sns_item" @click="openYotube">
            <img src="/media/images/home/youtube.png" alt="유투브" />
          </div>
          <div class="sns_item" @click="openNaverBlog">
            <img src="/media/images/home/naverblog.png" alt="네이버블로그" />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import axios from 'axios';
export default {
  name: 'FooterComponent',
  emits: ['openinqury', 'openonetoone', 'openappstore', 'gohomeservice', 'goprivacy'],
  data() {
    return {
      noticeList: [],
    };
  },
  created() {
    this.getTopNoticeList();
  },

  mounted() {},

  methods: {
    /**
     * @description 공지사항 Top List
     * @author CHOI DAE GEON
     */
    getTopNoticeList() {
      axios
        .post('/api/menu/getCommonNoticePopupList', { nonmemberShowing: 'Y' })
        .then(res => {
          this.noticeList = res.data.result.slice(0, 3);
        })
        .catch(err => {
          console.log(err);
          this.noticeList = [];
        });
    },
    /**
     * @description 공지사항 리스트 이동
     * @author CHOI DAE GEON
     */
    goNoticeList() {
      this.$router.push({ name: 'BrandNoticeList' }).catch(() => {});
    },
    /**
     * @description 공지사항 Detail
     * @author CHOI DAE GEON
     */
    goNoticeDetail(noticeId) {
      this.$router.push({ path: `/brand/noticedetail/${noticeId}` }).catch(() => {});
    },
    /**
     * @description FAQ리스트 이동
     * @author CHOI DAE GEON
     */
    goFaqList() {
      // this.$router.push({ name: 'FaqListView' }).catch(() => { });
    },
    /**
     * @description 제휴문의
     * @author CHOI DAE GEON
     */
    openInquiry() {
      this.$emit('openinqury');
    },
    /**
     * @description 1:1문의
     * @author CHOI DAE GEON
     */
    openOneToOne() {
      this.$emit('openonetoone');
    },
    /**
     * @description 프랜차이즈관리
     * @author CHOI DAE GEON
     */
    goFranchise() {
      location.href = 'https://brand.aplayz.co.kr';
    },
    /**
     * @description 서비스이용약관
     * @author CHOI DAE GEON
     */
    goHomeService() {
      this.$emit('gohomeservice');
      //this.$router.push({ name: "HomeService" }).catch(() => { });
    },
    /**
     * @description 개인정보처리방침
     * @author CHOI DAE GEON
     */
    goHomePrivacy() {
      this.$emit('goprivacy');
    },
    /**
     * @description 인스타그램 오픈
     * @author CHOI DAE GEON
     */
    openInstgram() {
      window.open('https://www.instagram.com/aplayz_official/', '_blank');
    },
    /**
     * @description 유투브 오픈
     * @author CHOI DAE GEON
     */
    openYotube() {
      window.open('https://www.youtube.com/@aplayz_official', '_blank');
    },
    /**
     * @description 네이버블로그 오픈
     * @author CHOI DAE GEON
     */
    openNaverBlog() {
      window.open('https://blog.naver.com/aplayz_official', '_blank');
    },

    /**
     * @description 스토어 Open
     * @param {*} storeGb
     * @author CHOI DAE GEON
     */
    clickAppStore(storeGb = 'GoogleStore') {
      //모바일일 경우
      if (/Mobi/i.test(window.navigator.userAgent)) {
        window.open(`https://aplayz.page.link/app`, 'blank');
      } else if (storeGb === 'GoogleStore') {
        //안드로이드
        window.open('https://play.google.com/store/apps/details?id=com.mobileaos.aplayz', 'blank');
      } else if (storeGb === 'AppleStore') {
        //아이폰
        window.open('https://apps.apple.com/kr/app/aplayz/id6446978790', 'blank');
      }
    },
  },
  computed: {
    getFormatNoticeList() {
      const formatList = [];

      this.noticeList.forEach(item => {
        const date = new Date(item.registDate);
        const yyyy = String(date.getFullYear()).substring(2, 4);
        const mm = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
        const dd = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;

        item.formatDate = `${yyyy}.${mm}.${dd}`;

        formatList.push(item);
      });

      return formatList;
    },
  },
};
</script>
<style scoped>
html {
  font-size: 16px;
}

/*######################## footer_box1 ########################*/
.footer_box1 {
  display: flex;
  justify-content: space-between;
  margin: 0 6%;
  margin-top: 72px;
  margin-bottom: 81px;
}

.footer_box1_left {
  width: 50%;
}

.footer_box1_left .notice_top_list {
  font-size: 14px;
  color: var(--white);
  font-weight: 500;
}

.notice_top_list .item {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 8px;
  cursor: pointer;
}

.item_wrap {
  display: flex;
  justify-content: space-between;
  width: 379px;
}

.notice_date {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-weight: 300;
}

.notice_top_list .item .notice_text {
  margin-right: 9px;
}

.notice_top_list .item.first {
  margin-bottom: 16px;
  font-size: 15px;
  display: flex;
}

.notice_arrorw {
  display: flex;
  align-items: center;
}

.arrow_img {
  width: 6px;
  height: 10px;
}

.notice_top_list .item.last {
  margin-bottom: 0;
}

.footer_box1_right {
  width: 18%;
  display: flex;
  justify-content: space-between;
}

.footer_box1_right .faq_list {
  color: var(--white);
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
}

.footer_box1_right .faq_list .faq_item {
  margin-bottom: 12px;
  cursor: pointer;
}

.franchise_list {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.franchise_list .franchise_item {
  margin-bottom: 12px;
  cursor: pointer;
}

.franchise_store_container {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.franchise_item .franchise_store {
  width: inherit;
  height: inherit;
  display: inline-block;
  vertical-align: bottom;
}

.franchise_item:first-child {
  color: rgba(255, 255, 255, 0.6);
  font-style: normal;
}

.app_down_list {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: var(--white);
}

.app_down_list .app_down_item {
  margin-bottom: 13px;
}

.app_down_item.cursor {
  cursor: pointer;
}

.app_down_list .app_down_item:first-child {
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 13px;
}

.app_store_download_list {
  display: flex;
  flex-direction: column;
}

.app_store_download_item {
  cursor: pointer;
}

.app_store_download_item.apple {
  margin-bottom: 13px;
}

.app_down_imgcontainer {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.store_img {
  width: inherit;
  height: inherit;
  display: inline-block;
  vertical-align: bottom;
}

/*######################## line ########################*/
.hr_line {
  background: rgba(255, 255, 255, 0.2);
  width: 100%;
  height: 1px;
  display: inline-block;
}

/*######################## footer_box2 ########################*/
.footer_box2 {
  margin: 0 6%;
  padding-top: 16px;
  padding-bottom: 81px;
  display: flex;
  justify-content: space-between;
}

.footer_box2_left {
  width: 75%;
}

.footer_box2_right {
  width: 18%;
}

.sns_group {
  display: flex;
  justify-content: flex-end;
}

.sns_item {
  width: 44px;
  height: 44px;
  background-color: rgba(255, 255, 255, 0.16);
  border-radius: 50%;
  display: inline-block;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sns_item:last-child {
  margin-right: 0;
}

.sns_item img {
  width: 24px;
  height: 24px;
}

.agree_information {
  font-size: 13px;
  line-height: 22px;
  font-weight: 500;
}

.agree_service {
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
}

.private_info {
  color: var(--white);
  cursor: pointer;
}

.aplayz_address {
  margin-top: 12px;
  word-break: keep-all;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
}

.aplayz_info {
  display: flex;
  flex-wrap: wrap;
  color: rgba(255, 255, 255, 0.6);
  font-style: normal;
  font-size: 13px;
}

.aplayz_info .info_item {
  margin-right: 1.2em;
}

.business_information {
  background-color: rgba(255, 255, 255, 0.28);
  border-radius: 4px;
  padding: 0 4px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
}

.aplayz_contact {
  display: flex;
  color: rgba(255, 255, 255, 0.6);
  font-style: normal;
}

.span_contanct {
  margin-right: 1.2em;
}

/*######################## 반응형 처리 501px~1023px ########################*/
@media all and (max-width: 1023px) {
  .footer_box1 {
    flex-direction: column;
  }

  .footer_box1_left {
    width: 100%;
  }

  .footer_box1_right {
    margin-top: 40px;
    width: 100%;
    justify-content: flex-start;
  }

  .footer_box2 {
    flex-direction: column-reverse;
  }

  .footer_box2_right {
    width: 100%;
    margin-bottom: 16px;
  }

  .sns_group {
    justify-content: flex-start;
  }

  .sns_item {
    margin: 0 12px;
  }

  .sns_item:first-child {
    margin-left: 0;
  }

  .footer_box2_left {
    width: 100%;
  }

  .app_down_list {
    margin-left: 64px;
  }

  .app_store_download_list {
    display: flex;
    flex-direction: row;
  }

  .app_store_download_item.apple {
    margin-bottom: 0;
  }

  .app_store_download_item.google_store {
    margin-left: 19px;
  }
}

/*######################## 반응형 처리 ~500px ########################*/
@media all and (max-width: 500px) {
  .footer_box1 {
    margin-top: 66px;
  }

  .footer_box1_left .notice_top_list {
    font-size: 13px;
  }

  .notice_top_list .item {
    font-size: 13px;
  }

  .item_wrap {
    width: 100%;
  }

  .notice_top_list .item.first {
    margin-bottom: 12px;
    font-size: 13px;
  }

  .footer_box1_right .faq_list {
    font-size: 13px;
  }

  .footer_box1_right .faq_list .faq_item,
  .franchise_list .franchise_item,
  .app_down_list .app_down_item:first-child {
    margin-bottom: 8px;
  }

  .franchise_list {
    font-size: 13px;
  }

  .app_down_list {
    font-size: 13px;
    line-height: 23px;
    margin-left: 32px;
  }

  .sns_item {
    width: 32px;
    height: 32px;
  }

  .sns_item img {
    width: 16px;
    height: 16px;
  }

  .aplayz_info .info_item {
    font-size: 12px;
  }

  .business_information {
    font-size: 10px;
  }

  .aplayz_contact {
    margin-top: 4px;
    font-size: 12px;
  }
}

/*######################## 반응형 처리 ~320px ########################*/
@media (max-width: 320px) {
  .footer_box1 {
    margin-bottom: 37px;
  }

  .footer_box1_right {
    display: flex;
    flex-direction: column;
  }

  .franchise_list,
  .app_down_list {
    margin-top: 22px;
    margin-left: 0;
  }

  .app_store_download_list {
    display: flex;
    flex-direction: column;
  }

  .app_store_download_item.google_store {
    margin-left: 0;
    margin-top: 10px;
  }
}
</style>
